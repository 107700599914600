import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

const AUTONUMERIC_FORMAT = {
  currencySymbol: '$',
  currencySymbolPlacement: 'p',
  digitGroupSeparator: '.',
  decimalCharacter: ',',
  decimalPlaces: 0,
  minimumValue: 1,
  maximumValue: 2147483647,
  overrideMinMaxLimits: 'invalid',
  unformatOnSubmit: true,
  modifyValueOnWheel: false, 
  modifyValueOnUpDownArrow: false
}

export default class extends Controller {
  static targets = ['currencyInput']
  static values = {
    minMoney: Number
  }

  currencyInputTargetConnected(element) {
    AUTONUMERIC_FORMAT.decimalCharacter = this.element.getAttribute('data-autonumeric--currency-decimal-separator') || AUTONUMERIC_FORMAT.decimalCharacter;
    AUTONUMERIC_FORMAT.digitGroupSeparator = this.element.getAttribute('data-autonumeric--currency-thousands-separator') || AUTONUMERIC_FORMAT.digitGroupSeparator;
    AUTONUMERIC_FORMAT.decimalPlaces = this.element.getAttribute('data-autonumeric--currency-rounding-decimal') || AUTONUMERIC_FORMAT.decimalPlaces;

    if(this.hasMinMoneyValue) {
      AUTONUMERIC_FORMAT['minimumValue'] = this.minMoneyValue
    }
    new AutoNumeric(element, AUTONUMERIC_FORMAT)
  }
}
