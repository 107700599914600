import { Controller } from 'stimulus'
import I18n from 'i18n-js';

export default class extends Controller {
  connect() {
    this.addLocale()
  }

  appendNewOption (selector, optionName, optionValue, selected = false) {
    let option = this.buildOption(optionName, optionValue, selected)
    selector.getElementsByClassName('dropdown-menu')[0].appendChild(option)
  }

  prependNewOption (selector, optionName, optionValue, selected = false) {
    let option = this.buildOption(optionName, optionValue, selected)
    let menu = selector.getElementsByClassName('dropdown-menu')[0]
    menu.getElementsByClassName('cf-selector-option')[0].insertAdjacentElement('afterend', option)
  }

  buildOption(name, value, selected) {
    const container = document.createElement('div')
    let optionClass = 'cf-selector-option'
    if (selected) optionClass += ' selected'
    let textHTML = `
      <div class="${optionClass}" data-action="click->cf-selector#changeOption" data-name="${name}" data-value="${value}">
        <div class="option-icon">
          <i class="fa-solid fa-circle-dot"></i>
          <i class="default fa fa-circle-thin fs-16"></i>
        </div>
        <div class="option-name">${name}</div>
      </div>
    `
    container.innerHTML = textHTML

    return container.firstChild.nextSibling
  }

  clearOptions (selector) {
    selector.querySelectorAll('.cf-selector-option').forEach(option => option.remove())
  }

  removeOption(selector, option) {
    selector.querySelector(`.cf-selector-option[data-value='${option}']`).remove()
  }

  turboMessage(message_type, message) {
    let flash = document.getElementById('flash')
    let flashMessage = document.createElement('turbo-frame')
    flashMessage.setAttribute('id', 'turbo_message')
    let url = '/async/turbo_message'
    let params = new URLSearchParams({ message_type: message_type, message: message })
    flashMessage.src = `${url}?${params}`
    flash.innerHTML = ''
    flash.appendChild(flashMessage)
  }

  addLocale() {
    const community_language = document.querySelector('#community_language').value;
    if (community_language) {
      I18n.locale = community_language;
    }
  }

  translateRoute(route, params = {}) {
    const pathName = `${route}_${I18n.locale}_path`;
    if (params && Object.keys(params).length > 0) {
      const path = Routes[pathName](params);
      return path;
    }
    return Routes[pathName]();
  }
}
