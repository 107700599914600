import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['priceTypeSelect', 'priceInputType', 'priceInput', 'periocityInput']
  static values = {
    currencyField: Object,
    percentField: Object
  }

  updatePriceAndPeriocityInputs() {
    const autonumericElement = AutoNumeric.getAutoNumericElement(this.priceInputTarget);
   if (this.priceTypeSelectTarget.value == 0) {
      this.priceInputTypeTarget.innerHTML = '$'
      if (this.hasCurrencyFieldValue) {
        autonumericElement.update($.parseAutonumericData(this.currencyFieldValue));
      }
      this.priceInputTarget.value = 0;
      this.periocityInputTarget.disabled = false
    } else {
      this.priceInputTypeTarget.innerHTML = '%'
      if (this.hasPercentFieldValue) {
        const percentField = $.parseAutonumericData(this.percentFieldValue);
        percentField['decimalCharacter'] = '.';
        percentField['digitGroupSeparator'] = ',';
        autonumericElement.update(percentField);
      }
      this.priceInputTarget.value = 0;
      this.periocityInputTarget.disabled = true
      this.periocityInputTarget.value = false
    }
  }
}
