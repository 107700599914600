import { Controller } from "stimulus";

export default class extends Controller {
  saveAndDuplicate(event) {
    const invoiceId = event.target.dataset.invoiceId;
    const url = Routes.save_and_duplicate_admin_invoice_path(invoiceId);

    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          window.location.href = data.redirect_url;
        });
      }
    });
  }
}
