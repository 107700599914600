import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'submitButton']

  checkInputs(){
    const allFilled = this.inputTargets.every(input => input.value.trim().length > 3);

    if (allFilled) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'true');
    }
  }

  maskAndLabelRutField({ target: input, params: { country, placeholder }}) {
    if (country === 'CL') {
      $(input).mask('00.000.000-A', { reverse: true, placeholder: placeholder });
    } else {
      $(input).unmask();
    }
  }
}
