import { Controller } from 'stimulus'

export default class extends Controller {
	static targets = ['submitButton']

	connect() {
		this.toggleEnableSubmitButton()
		const autonumericInput = document.querySelector('#leaving_community_associated_value')

		new AutoNumeric(autonumericInput)
	}

	toggleEnableSubmitButton() {
		const leavingDateInput = document.querySelector('#leaving_community_leaving_date')
		const deactivationDateInput = document.querySelector('#leaving_community_deactivation_date')
		const reasonsToLeaveMultiselect = document.querySelector('#leaving_community_reasons_to_leave-selector > select')
		const currencyTypeSelector = document.querySelector('#leaving_community_currency_type-hidden-value')
		const associatedValueInput = document.querySelector('#leaving_community_associated_value')

		const allRequiredFilled = [
			leavingDateInput.value.length > 0,
			deactivationDateInput.value.length > 0,
			Array.from(reasonsToLeaveMultiselect.options).filter((option) => option.selected).length > 0,
			currencyTypeSelector.value.length > 0,
			associatedValueInput.value.length > 0,
		].every((filled) => filled)

		if (!allRequiredFilled) {
			this.submitButtonTarget.classList.add('disabled')

			return
		}

		this.submitButtonTarget.classList.remove('disabled')
	}
}
