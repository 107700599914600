
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    newCategoryText: String
  }
  static targets = ["serviceBillingCategories", "billingCustomCategory", "serviceBillingCategoriesSelection", "submitDisabledHelperMessage", "remunerationsSubmitButton"]

  connect() {
    this.handleRemunerationsCustomCategorySelector()
    this.handleDisableSubmitButton()
  }

  isRemunerationsTabActive() {
    return this.remunerationEditTabTarget.classList.contains('active');
  }

  handleRemunerationsCustomCategorySelector() {
    if (this.serviceBillingCategoriesTarget.selectedOptions[0].text == this.newCategoryTextValue) {
      this.billingCustomCategoryTarget.style.display = "block";
      if (this.serviceBillingCategoriesSelectionTarget.options.length < 2) {
        this.handleRemunerationsNewCategoryField();
      }
    } else {
      this.billingCustomCategoryTarget.style.display = "none";
    }
  }

  handleDisableSubmitButton() {
    if (this.serviceBillingCategoriesSelectionTarget.selectedOptions[0].text == this.newCategoryTextValue && this.serviceBillingCategoriesTarget.selectedOptions[0].text == this.newCategoryTextValue) {
      this.submitDisabledHelperMessageTarget.style.display = "block";
      this.remunerationsSubmitButtonTarget.setAttribute('disabled', '');
    } else {
      this.submitDisabledHelperMessageTarget.style.display = "none";
      this.remunerationsSubmitButtonTarget.removeAttribute('disabled');
    }
  }

  handleNoCategoryScenario() {
    if (this.serviceBillingCategoriesSelectionTarget.options.length < 2) {
      this.handleRemunerationsNewCategoryField();
    }
  }

  updateOtherCategorySelect(response, closeDialog) {
    this.serviceBillingCategoriesSelectionTarget.selectedOptions[0].selected = false;
    this.serviceBillingCategoriesSelectionTarget.add(new Option(response.category.name, response.category.id, true, true));
    this.handleDisableSubmitButton();
    closeDialog();
  }

  handleRemunerationsNewCategoryField() {
    this.handleDisableSubmitButton();
    if (this.serviceBillingCategoriesSelectionTarget.selectedOptions[0].text == this.newCategoryTextValue) {
      const params = {
        isAjaxRequest: true,
        callbackContent: this.updateOtherCategorySelect.bind(this),
        callbackError: null,
        request: {
          path: `/categorias`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        }
      };
      showDynamicFieldsDialogModal(params, this.handleDisableSubmitButton.bind(this));
    }
  }
}
