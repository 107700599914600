import { Controller } from 'stimulus';
import I18n from 'i18n-js';

const multiselectPropertyUserIdentifier = 'multiselect-property-user';
const pedestrianModeName = 'pedestrianOnly';
const vehicularModeName = 'vehicularOnly';

export default class extends Controller {
  // The indentations in the targets array are intentional to represent the nesting level in the form
  static targets = [
    'currentPropertyUserSetting',
    'accessModeApplicationCheckbox',
    'accessModePhysicalCheckbox',
      'nestedForm',
        'nestedQrCodeForm',
          'qrCodeEnabledCheckbox',
        'nestedPedestrianCardForm',
          'pedestrianCardEnabledCheckbox',
            'pedestrianCardNumber',
        'vehicleAccessEnabledCheckbox',
          'nestedVehicleAccessForm',
            'remoteOpeningEnable',
            'tagOptionsCheckbox',
              'nestedTagOptionsForm',
                'tagOptionsPropertyUser',
                'tagOptionsAdminManager',
                  'tagNumberList',
                  'tagNumber',
                  'removeTagButton',
                  'addNewTagButton',
                  'tagLimitReachedText',
    'submitButton'
  ]

  static values = {
    cardMinNumberOfDigits: Number,
    cardMaxNumberOfDigits: Number,
    loadedPropertyUserId: Number,
    tagList: Array,
    mode: String,
    updatePath: String,
    validateCardNumberPath: String
  }

  // Initializers

  connect() {
    if (this.loadedPropertyUserIdValue) {
      this.disableFormAgain()
      this.getPropertyUserSetting(this.loadedPropertyUserIdValue)
    } else {
      this.multiselectPropertyUser = document.getElementById(multiselectPropertyUserIdentifier)
    }
  }

  // Request functions

  async getCardNumberValidation(cardNumber) {
    const url = `/access_control/cards/${cardNumber}/validate_number`
    const headers = this.defaultHeaders()

    const response = await fetch(url, { method: 'GET', headers: headers })
    const extractedJson = await response.json()
    return extractedJson
  }

  getPropertyUserSetting(propertyUserId) {
    const url = this.updatePathValue + '?property_user_id=' + propertyUserId
    const headers = this.defaultHeaders()

    fetch(url, {
      method: 'GET',
      headers: headers
    })
    .then(response => response.json())
    .then(data => this.handlePropertyUserSettingResponse(data))
  }

  handlePropertyUserSettingResponse(data) {
    if (!data.success) {
      triggerAlert(I18n.t('messages.errors.access_control.edit.setting_load'))
      allow_multiple_alerts()
      return
    }

    this.propertyUserSetting = data.data
    this.currentPropertyUserSettingTarget.value = JSON.stringify(this.propertyUserSetting)
    this.tagListValue = this.propertyUserSetting.tagNumber
    this.populateForm(this.propertyUserSetting)
  }

  // Functions to populate form

  populateForm(propertyUserSetting) {
    this.accessModeApplicationCheckboxTarget.removeAttribute('disabled')
    this.accessModePhysicalCheckboxTarget.removeAttribute('disabled')
    if (1 === propertyUserSetting.accessMode || 3 === propertyUserSetting.accessMode) {
      this.populateAppForm(propertyUserSetting)
    }

    if (2 === propertyUserSetting.accessMode || 3 === propertyUserSetting.accessMode) {
      this.populatePhysicalForm(propertyUserSetting)
      this.populateAllTagOptions(propertyUserSetting)
    }
    this.populateVehicularsTagList(propertyUserSetting)
  }

  populateAppForm(propertyUserSetting) {
    this.accessModeApplicationCheckboxTarget.checked = true
    this.nestedFormTarget.classList.remove('hidden')

    if (!this.vehicularOnly()) {
      this.nestedQrCodeFormTarget.classList.remove('hidden')
      this.qrCodeEnabledCheckboxTarget.checked = propertyUserSetting.qrCodeEnabled
      this.accessModeCheckboxChange()
    }

    if (!this.pedestrianOnly()) {
      this.vehicleAccessEnabledCheckboxTarget.checked = this.vehicularOnly() ? true : propertyUserSetting.vehicleAccessEnabled
      this.vehicleAccessEnabledToggle()
    }

    this.verifyValidSubmit()
  }

  populatePhysicalForm(propertyUserSetting) {
    this.accessModePhysicalCheckboxTarget.checked = true
    this.nestedFormTarget.classList.remove('hidden')

    if (!this.vehicularOnly()) {
      this.nestedPedestrianCardFormTarget.classList.remove('hidden')
      this.pedestrianCardEnabledCheckboxTarget.checked = propertyUserSetting.pedestrianCardEnabled
      this.pedestrianCardNumberTarget.value = propertyUserSetting.pedestrianCardNumber
      if (this.pedestrianCardEnabledCheckboxTarget.checked) this.pedestrianCardNumberTarget.removeAttribute('disabled')
    }

    if (!this.pedestrianOnly()) {
      this.vehicleAccessEnabledCheckboxTarget.checked = this.vehicularOnly() ? true : propertyUserSetting.vehicleAccessEnabled
      this.vehicleAccessEnabledToggle()
    }

    this.verifyValidSubmit()
  }


  populateAllTagOptions(propertyUserSetting) {
    this.populateTagOptions(
      propertyUserSetting,
      this.tagOptionsPropertyUserTarget,
      this.tagOptionsAdminManagerTarget,
      this.nestedTagOptionsFormTarget,
      this.tagOptionsCheckboxTarget
    )
  }

  populateTagOptions(propertyUserSetting, tagOptionsPropertyUser, tagOptionsAdminManager, nestedTagOptionsForm, tagOptionsCheckbox) {
    let checkbox_checked = true
    let disable_number_input = true
    if (parseInt(tagOptionsPropertyUser.value) === propertyUserSetting.tagOptions) {
      tagOptionsPropertyUser.checked = true
    } else if (parseInt(tagOptionsAdminManager.value) === propertyUserSetting.tagOptions) {
      tagOptionsAdminManager.checked = true
      disable_number_input = false
    } else {
      checkbox_checked = false
      tagOptionsPropertyUser.checked = false
      tagOptionsAdminManager.checked = false
    }
    tagOptionsCheckbox.checked = checkbox_checked
    tagOptionsCheckbox.checked ? nestedTagOptionsForm.classList.remove('hidden') : nestedTagOptionsForm.classList.add('hidden')
    if (disable_number_input) {
      this.tagNumberTargets.map(target => target.setAttribute('disabled', true))
      this.addNewTagButtonTarget.setAttribute('disabled', true)
      this.removeTagButtonTargets.map(button => button.setAttribute('disabled', true))
    } else {
      this.tagNumberTargets.map(target => target.removeAttribute('disabled'))
      this.addNewTagButtonTarget.removeAttribute('disabled')
      this.removeTagButtonTargets.map(button => button.removeAttribute('disabled'))
    }
  }

  populateVehicularsTagList(propertyUserSetting) {
    this.tagListValue.map((value, index) => {
      this.tagNumberListTarget.appendChild(this.getTagTemplate(value, index))
    })
    this.canAddMoreItems()
  }


  getTagTemplate(value, index) {
    let template = document.querySelector('#additional_tag')
    let newItem = template.content.cloneNode(true)
    newItem.querySelector('input').value = value
    newItem.querySelector('input').name = `tag_number_${index}`
    newItem.querySelector('input').id =`tag_number_${index}`
    return newItem
  }

  // Event handlers

  selectPropertyUser(_event) {
    const propertyUserId = this.multiselectPropertyUser.selectedOptions[0].value
    this.disableFormAgain()
    this.getPropertyUserSetting(propertyUserId)
  }

  accessModeCheckboxChange(_event) {
    const someAccessMode = this.accessModeApplicationCheckboxTarget.checked || this.accessModePhysicalCheckboxTarget.checked
    // If none of the checkboxes is checked we hide the form
    if (!someAccessMode) return this.hideNestedForm()

    this.showNestedForm()
    if (!this.vehicularOnly()) this.showDeeplyNestedPedestrianForms()
    this.verifyValidSubmit()
  }

  vehicleAccessEnabledCheckboxChange(_event) {
    this.vehicleAccessEnabledToggle()
    this.verifyValidSubmit()
  }

  pedestrianCardCheckboxChange(_event) {
    if (this.pedestrianCardEnabledCheckboxTarget.checked) {
      this.pedestrianCardNumberTarget.removeAttribute('disabled')
    } else {
      this.resetInvalidCardNumber(this.pedestrianCardNumberTarget)
      this.pedestrianCardNumberTarget.setAttribute('disabled', true)
    }
    this.verifyValidSubmit()
  }

  tagOptionsCheckboxChange(_event) {
    if (this.tagOptionsCheckboxTarget.checked) {
      this.nestedTagOptionsFormTarget.classList.remove('hidden')
      const atLeastOneOptionChecked = this.tagOptionsPropertyUserTarget.checked || this.tagOptionsAdminManagerTarget.checked
      if (!atLeastOneOptionChecked) {
        this.tagOptionsPropertyUserTarget.checked = true
      }
    } else {
      this.nestedTagOptionsFormTarget.classList.add('hidden')
    }
    this.verifyValidSubmit()
  }

  tagOptionsRadioButtonChange(_event) {
    if (this.tagOptionsPropertyUserTarget.checked) {
      this.resetInvalidCardNumber(this.tagNumberTarget)
      this.tagNumberTargets.map(target => target.setAttribute('disabled', true))
      this.removeTagButtonTargets.map(button => button.setAttribute('disabled', true))
      this.addNewTagButtonTarget.setAttribute('disabled', true)
    } else {
      this.tagNumberTargets.map(target => target.removeAttribute('disabled'))
      this.removeTagButtonTargets.map(button => button.removeAttribute('disabled'))
      this.addNewTagButtonTarget.removeAttribute('disabled')
    }
    this.verifyValidSubmit()
  }

  qrCodeEnabledCheckboxChange(_event) {
    this.verifyValidSubmit()
  }

  async verifyNumber(event) {
    const formTarget = event.target
    const cardNumber = formTarget.value

    if (cardNumber.length < this.cardMinNumberOfDigitsValue) {
      this.restoreCardNumberToInitialState(formTarget)
      this.cardNumberInvalidations[formTarget.id] = true // We still invalidate the field as it does not have the length required
      this.verifyValidSubmit()
      return
    }

    if (this.cardNumberIsFromCurrentPropertyUserSetting(formTarget.id, cardNumber)) {
      this.removeCardNumberError(formTarget)
      this.verifyValidSubmit()
      return
    }

    if(this.tagNumberIsBeingUsed(cardNumber)) {
      this.addCardNumberError(formTarget, I18n.t('views.access_control.edit.card_number.already_taken'))
      this.verifyValidSubmit()
      return
    }

    this.addCardNumberValidationLoading(formTarget)
    const validationResponse = await this.getCardNumberValidation(cardNumber)
    const requestSuccess = validationResponse.success
    const numberIsBeingUsed = requestSuccess && validationResponse.data // If data is truthy, the number is already being used

    if (!requestSuccess || numberIsBeingUsed) {
      this.addCardNumberError(formTarget, validationResponse.message)
    } else {
      this.removeCardNumberError(formTarget)
    }

    this.verifyValidSubmit()
  }

  preventUnwantedCharacters(event) {
    const notNumeric = (event.charCode < 48 || event.charCode > 57)
    if (notNumeric) return event.preventDefault()

    const maxLengthReached = event.target.value.length >= this.cardMaxNumberOfDigitsValue
    if (maxLengthReached) {
      const selectionLength = event.target.selectionEnd - event.target.selectionStart
      // If there is one or more characters selected, the user can replace them with the new input
      if (selectionLength <= 0) return event.preventDefault()
    }
  }

  preventPaste(event) {
    event.preventDefault()
  }

  submitAll(event) {
    if (this.sameCardNumberInBothForms()) {
      event.preventDefault()
      triggerAlert(I18n.t('views.access_control.edit.card_number.repeated'))
      allow_multiple_alerts()
    }
  }

  addNewVehicularTag(event) {
    event.preventDefault()
    this.tagListValue = [...this.tagListValue, '']
    this.tagNumberListTarget.appendChild(this.getTagTemplate('', this.tagListValue.length - 1));
    this.canAddMoreItems()
  }

  removeVehicularTag(event) {
    event.preventDefault()
    this.tagListValue = this.tagListValue.slice(0, -1)
    this.canAddMoreItems()
    const buttonContainer = event.target.parentElement
    buttonContainer.closest('div .tag').remove();
    this.verifyValidSubmit()
  }

  canAddMoreItems() {
    if(this.tagListValue.length >= 10){
      this.addNewTagButtonTarget.classList.add('hidden')
      this.tagLimitReachedTextTarget.classList.remove('hidden')
    } else {
      this.addNewTagButtonTarget.classList.remove('hidden')
      this.tagLimitReachedTextTarget.classList.add('hidden')
    }
  }

  // Auxiliary functions

  hideNestedForm(){
    this.nestedFormTarget.classList.add('hidden')

    // When the TAG number is invalid we do not bother with keeping it, so its discarded
    if (!this.pedestrianOnly()) this.resetInvalidCardNumber(this.tagNumberTarget)
    this.verifyValidSubmit()
  }

  showNestedForm(){
    this.nestedFormTarget.classList.remove('hidden')
    if (!this.pedestrianOnly()) {
      // If the TAG is already enabled, we must enable the vehicle access and remote opening
      if (this.tagOptionsCheckboxTarget.checked) this.vehicleAccessEnabledCheckboxTarget.checked = true
      this.vehicleAccessEnabledToggle()
    }
  }

  showDeeplyNestedPedestrianForms(){
    this.accessModeApplicationCheckboxTarget.checked ? this.nestedQrCodeFormTarget.classList.remove('hidden') : this.nestedQrCodeFormTarget.classList.add('hidden')

    if (this.accessModePhysicalCheckboxTarget.checked) {
      this.nestedPedestrianCardFormTarget.classList.remove('hidden')
    } else {
      this.nestedPedestrianCardFormTarget.classList.add('hidden')
      this.resetInvalidCardNumber(this.pedestrianCardNumberTarget)
    }
  }

  cardNumberIsFromCurrentPropertyUserSetting(formTargetId, cardNumber) {
    return !this.vehicularOnly() && formTargetId === this.pedestrianCardNumberTarget.id &&  cardNumber === this.propertyUserSetting.pedestrianCardNumber
  }

  tagNumberIsBeingUsed(cardNumber) {
    const currentTagList = this.tagNumberTargets.map(item => item.value)
    const coincidences = currentTagList.filter(item => item === cardNumber)
    return coincidences.length > 1
  }

  vehicleAccessEnabledToggle() {
    if (this.vehicleAccessEnabledCheckboxTarget.checked) {
      this.nestedVehicleAccessFormTarget.classList.remove('hidden')
      this.remoteOpeningEnableTarget.checked = true
      this.remoteOpeningEnableTarget.setAttribute('disabled', true)
    } else {
      this.nestedVehicleAccessFormTarget.classList.add('hidden')
    }
  }

  sameCardNumberInBothForms() {
    if (this.pedestrianOnly() || this.vehicularOnly()) return false

    return this.pedestrianCardNumberTarget.value && this.tagNumberTarget.value && this.pedestrianCardNumberTarget.value === this.tagNumberTarget.value
  }

  addCardNumberError(formTarget, errorMessage){
    this.cardNumberInvalidations[formTarget.id] = true
    this.changeCardNumberFormAppearance(formTarget, 'rejected', errorMessage)
  }

  addCardNumberValidationLoading(formTarget){
    // Force the user to wait the result
    this.changeCardNumberFormAppearance(formTarget, 'normal', I18n.t('views.access_control.edit.card_number.validating'))
    this.submitButtonTarget.setAttribute('disabled', true)
  }

  removeCardNumberError(formTarget){
    delete this.cardNumberInvalidations[formTarget.id]
    this.changeCardNumberFormAppearance(formTarget, 'valid', I18n.t('views.access_control.edit.card_number.valid'))
  }

  restoreCardNumberToInitialState(formTarget){
    delete this.cardNumberInvalidations[formTarget.id]
    const originalText = I18n.t('views.access_control.edit.card_number.length', { min: this.cardMinNumberOfDigitsValue, max: this.cardMaxNumberOfDigitsValue })
    this.changeCardNumberFormAppearance(formTarget, 'normal', originalText)
  }

  changeCardNumberFormAppearance(formTarget, mode, message){
    const bottomText = formTarget.parentElement.nextElementSibling

    if (mode === 'rejected') {
      formTarget.classList.add('ca-form-error-border')
      bottomText.classList.remove('valid')
      bottomText.classList.add('rejected')
    } else if (mode === 'valid') {
      formTarget.classList.remove('ca-form-error-border')
      bottomText.classList.remove('rejected')
      bottomText.classList.add('valid')
    } else {
      formTarget.classList.remove('ca-form-error-border')
      bottomText.classList.remove('rejected')
      bottomText.classList.remove('valid')
    }

    bottomText.innerText = message
  }

  resetInvalidCardNumber(formTarget) {
    if (this.cardNumberInvalidations[formTarget.id]) {
      this.restoreCardNumberToInitialState(formTarget)
      formTarget.value = ''
    }
  }

  verifyValidSubmit() {
    const blockButton = this.emptyAccessModes() || this.emptyLabels() || this.emptyPhysicalItems() ||  this.invalidPedestrianCardNumber() || this.invalidTagNumber()
    this.updateSubmitButton(blockButton)
  }

  emptyAccessModes() {
    return !this.accessModeApplicationCheckboxTarget.checked && !this.accessModePhysicalCheckboxTarget.checked
  }

  emptyLabels() {
    return this.accessModeApplicationCheckboxTarget.checked &&
           (this.vehicularOnly() || !this.qrCodeEnabledCheckboxTarget.checked) &&
           (this.pedestrianOnly() || !this.vehicleAccessEnabledCheckboxTarget.checked)
  }

  emptyPhysicalItems() {
    return this.accessModePhysicalCheckboxTarget.checked &&
           (this.vehicularOnly() || !this.pedestrianCardEnabledCheckboxTarget.checked) &&
           (
            this.pedestrianOnly() ||
            (
              !this.vehicleAccessEnabledCheckboxTarget.checked ||
              (this.vehicleAccessEnabledCheckboxTarget.checked && !this.tagOptionsCheckboxTarget.checked)
            )
           )
  }

  invalidPedestrianCardNumber() {
    return !this.vehicularOnly() && this.accessModePhysicalCheckboxTarget.checked && this.pedestrianCardEnabledCheckboxTarget.checked &&
           (!this.pedestrianCardNumberTarget.value || this.cardNumberInvalidations[this.pedestrianCardNumberTarget.id])
  }

  invalidTagNumber() {
    return !this.pedestrianOnly() && this.tagOptionsCheckboxTarget.checked && this.tagOptionsAdminManagerTarget.checked &&
           Object.keys(this.cardNumberInvalidations).length > 0
  }

  disableFormAgain() {
    this.propertyUserSetting = {}
    this.cardNumberInvalidations = {}
    this.accessModeApplicationCheckboxTarget.checked = false
    this.accessModePhysicalCheckboxTarget.checked = false
    this.accessModeApplicationCheckboxTarget.setAttribute('disabled', true)
    this.accessModePhysicalCheckboxTarget.setAttribute('disabled', true)
    this.submitButtonTarget.setAttribute('disabled', true)
    this.nestedFormTarget.classList.add('hidden')

    if (!this.vehicularOnly()) {
      this.nestedPedestrianCardFormTarget.classList.add('hidden')
      this.nestedQrCodeFormTarget.classList.add('hidden')
      this.restoreCardNumberToInitialState(this.pedestrianCardNumberTarget)
      this.pedestrianCardNumberTarget.value = ''
    }
  }

  updateSubmitButton(blockButton) {
    if (blockButton) {
      this.submitButtonTarget.setAttribute('disabled', true)
    } else {
      this.submitButtonTarget.removeAttribute('disabled')
    }
  }

  pedestrianOnly() {
    return this.modeValue === pedestrianModeName
  }

  vehicularOnly() {
    return this.modeValue === vehicularModeName
  }

  defaultHeaders() {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  }
}
