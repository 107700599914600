import BaseController from '../base_controller'

export default class extends BaseController {
  static values = { 
    paymentPrice: Number,
    initialAssignments: Object, 
    debtDetails: Object,
    localeCode: String,
    currencyCode: String,
    paymentId: Number }

  connect() {
    // Detect event before page reload or close
    document.addEventListener('beforeunload', this.handleBeforeUnload())

    this.setSelectedDebts()
    this.handleEnablingCheckboxes()
    this.enableSaveButton()
  }

  setInitialAssignments(){
    Object.entries(this.initialAssignmentsValue).forEach(([key, value]) => {
      this.saveValue(`amount_to_paid_debt_id_${key}`, value)
      let currencyNumberInput = document.getElementById(`amount_to_paid_debt_id_${key}`)
      
      if (currencyNumberInput) {
        let currencyStringInput = document.getElementById(`amount_to_paid_debt_id_${key}_masked`)
        let checkbox = document.getElementById(`debt_id_${key}`)
        currencyNumberInput.value = value
        currencyStringInput.value = value

        this.selectAndFillRow(currencyNumberInput, currencyStringInput, checkbox, value)
      }
    })
  }

  setSelectedDebts() {
    const sessionData = {};

    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      sessionData[key] = sessionStorage.getItem(key);
    }

    Object.entries(sessionData).forEach(([key, value]) => {
      let currencyNumberInput = document.getElementById(key)

      if (currencyNumberInput) {
        let currencyStringInput = document.getElementById(`${key}_masked`)
        let debt_id = key.split("amount_to_paid_debt_id_")[1]
        let checkbox = document.getElementById(`debt_id_${debt_id}`)

        this.selectAndFillRow(currencyNumberInput, currencyStringInput, checkbox, value)
      }
    });
  }

  selectAndFillRow(currencyNumberInput, currencyStringInput, checkbox, value) {
    currencyNumberInput.value = value
    currencyStringInput.value = value
    currencyStringInput.removeAttribute('disabled')
    checkbox.checked = true
  }

  validateInput(event) {
    setTimeout(() => {
      this.validateValueOfSelectedDebts(event)
      this.enableSaveButton()
      this.handleEnablingCheckboxes()
    }, 0)
  }

  validateValueOfSelectedDebts(event) {
    const sessionData = this.sessionStorageHash()
    let debt_id = event.target.id.split("amount_to_paid_debt_id_")[1]

    // Sum all the values from SessionStorage, excluding the value of the debt that will be edited.
    let totalAssigned = Object.entries(sessionData)
      .filter(([key]) => key !== event.target.id)
      .reduce((total, [, value]) => total + value, 0)

    if (totalAssigned + Number(event.target.value) <= this.paymentPriceValue && Number(event.target.value) <= this.getDebtBalance(debt_id)){
      this.hide_warning_debt(debt_id)
      this.saveValue(event.target.id, event.target.value)
    } else {
      let currencyStringInput = document.getElementById(`${event.target.id}_masked`)
      let currencyNumberInput = document.getElementById(event.target.id)

      this.restoreToZero(currencyStringInput, currencyNumberInput)
      this.show_warning_debt(debt_id)
    }
  }

  getDebtBalance(debt_id) {
    if (this.initialAssignmentsValue[debt_id]) {
      return Number(this.initialAssignmentsValue[debt_id])
    } else {
      return Number(this.debtDetailsValue[debt_id]['money_balance'])
    }
  }

  async sendForm() {
    let save_btn = document.getElementById('save-btn')
    save_btn.setAttribute('disabled', true)
    save_btn.removeAttribute('data-action')

    const sessionData = this.sessionStorageHash()
    const patchUrl = this.translateRoute('edit_payment_assignments_payment', { id: this.paymentIdValue })
    sessionData['id'] = this.paymentIdValue;
    
    await fetch(patchUrl, {
      method: 'PATCH',
      headers:  {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify(sessionData),
      redirect: 'manual'
    })
    .then(response => { return response.json() })
    .then(data => {
      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      } else {
        console.log('response received:', data);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  // Enabled or disabled the input for the amount to be paid on the debt
  handleDebtSelection(event) {
    let checked = event.target.checked
    const string_debt_id = event.target.id
    const currencyInput = document.getElementById(`amount_to_paid_${string_debt_id}_masked`)
    let debt_id = string_debt_id.split('debt_id_')[1]

    if (checked){
      currencyInput.removeAttribute('disabled')
      this.autoCompleteCurrencyInput(debt_id)
    }else{
      let currencyStringInput = document.getElementById(`amount_to_paid_${string_debt_id}_masked`)
      let currencyNumberInput = document.getElementById(`amount_to_paid_${string_debt_id}`)

      this.restoreToZero(currencyStringInput, currencyNumberInput)
      this.hide_warning_debt(debt_id)
      currencyInput.setAttribute('disabled', true)
    }

    this.enableSaveButton()
    this.handleEnablingCheckboxes()
  }

  saveValue(id, value) {
    sessionStorage.setItem(id, value)
  }

  // Return to zero debt when this is deselected
  restoreToZero(currencyStringInput, currencyNumberInput) {  
    currencyNumberInput.value = 0
    currencyStringInput.value = '$ 0'
    sessionStorage.removeItem(currencyNumberInput.id)
  }

  autoCompleteCurrencyInput(debt_id) {
    const sessionData = this.sessionStorageHash()
    let totalAssigned = Object.values(sessionData).reduce((total, value) => total + value, 0);
    let valueAvailableToPay = this.paymentPriceValue - totalAssigned
    let moneyBalanceDebt = 0

    if ( valueAvailableToPay != 0 ) {
      if (this.initialAssignmentsValue[debt_id]) {
        moneyBalanceDebt = Number(this.initialAssignmentsValue[debt_id]) || 0
      } else {
        moneyBalanceDebt = Number(this.debtDetailsValue[debt_id]?.['money_balance']) || 0
      }

      let minValue = Math.min(...[valueAvailableToPay, moneyBalanceDebt])
      minValue = Math.round(minValue * 100) / 100

      if (minValue > 0) {
        let currencyStringInput = document.getElementById(`amount_to_paid_debt_id_${debt_id}_masked`)
        let currencyNumberInput = document.getElementById(`amount_to_paid_debt_id_${debt_id}`)

        this.saveValue(`amount_to_paid_debt_id_${debt_id}`, minValue)

        currencyNumberInput.value = minValue
        currencyStringInput.value = this.currencyString(minValue)
      }
    }
  }

  hide_warning_debt(debt_id) {
    let currencyStringInput = document.getElementById(`amount_to_paid_debt_id_${debt_id}_masked`)
    let warning = document.getElementById(`warning_debt_id_${debt_id}`)
    warning.style.display = 'none'
    currencyStringInput.classList.remove('border-red')
  }

  show_warning_debt(debt_id) {
    let currencyStringInput = document.getElementById(`amount_to_paid_debt_id_${debt_id}_masked`)
    let warning = document.getElementById(`warning_debt_id_${debt_id}`)
    warning.style.removeProperty('display')
    currencyStringInput.classList.add('border-red')
  }

  // get sesionStorage
  sessionStorageHash(){
    const sessionData = {};

    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
  
      if (key.includes('amount_to_paid_debt_id_')) {
        sessionData[key] = Number(sessionStorage.getItem(key));
      }
    }

    return sessionData
  }

  // format currency
  currencyString(number, decimal) {
    this.formatter = new Intl.NumberFormat(this.localeCodeValue, { style: 'currency', currency: this.currencyCodeValue, minimumFractionDigits: 0 , maximumFractionDigits: 0 })
    this.fraction = new Intl.NumberFormat(this.localeCodeValue, { style: 'currency', currency: this.currencyCodeValue })

    let formattedValue = ''
    if (number % 1 == 0){
      formattedValue = this.formatter.format(number)
    } else {
      formattedValue = this.fraction.format(number)
    }

    if (decimal > 0 && decimal < 10) {
      formattedValue = formattedValue.substring(0, formattedValue.length - 1)
    }

    return formattedValue.replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ')
  }

  enableSaveButton() {
    let save_btn = document.getElementById('save-btn')
    const sessionData = this.sessionStorageHash()
    const initialAssignmentsHash = Object.fromEntries(
      Object.entries( this.initialAssignmentsValue).map(([key, value]) => [
        `amount_to_paid_debt_id_${key}`, Number(value)
      ])
    );

    if (this.areObjectsEqual(sessionData, initialAssignmentsHash)) {
      save_btn.setAttribute('disabled', true)
      save_btn.removeAttribute('data-action')
    } else {
      let action = 'click->payment--debt-reassignment#sendForm'
      save_btn.removeAttribute('disabled')
      save_btn.setAttribute('data-action', action)
    }
  }

  areObjectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    return keys1.every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);
  }

  handleEnablingCheckboxes() {
    const sessionData = this.sessionStorageHash()
    const totalAssigned = Object.values(sessionData).reduce((acc, value) => acc + value, 0)

    if (totalAssigned < this.paymentPriceValue){
      this.enableCheckboxes()
    } else {
      this.disableCheckboxes()
    }
  }

  disableCheckboxes() {
    const uncheckedCheckboxes = document.querySelectorAll("input[type='checkbox'][id*='debt_id_']:not(:checked)")
    uncheckedCheckboxes.forEach(checkbox => {
      checkbox.disabled = true;
    })
  }

  enableCheckboxes() {
    const uncheckedCheckboxes = document.querySelectorAll("input[type='checkbox'][id*='debt_id_']:not(:checked)")
    uncheckedCheckboxes.forEach(checkbox => {
      checkbox.disabled = false;
    })
  }

  // Functions to handle sessionStorage cleanup

  markPagyClick(){
    sessionStorage.setItem('pagy_navigation', true)
  }

  handleBeforeUnload() {
    const isPagyNavigation = sessionStorage.getItem("pagy_navigation");

    if (isPagyNavigation) {
      sessionStorage.removeItem("pagy_navigation");
    } else {
      sessionStorage.clear();
      this.setInitialAssignments()
    }
  }
}
