import { Controller } from 'stimulus'

export default class extends Controller {
  toggleDisabledDeactivationDateInput({ currentTarget, params: { now } }) {
    const dateSelectorInput = document.querySelector('#leaving_community_deactivation_date-selector-input')
    const dateSelectorButton = document.querySelector('#leaving_community_deactivation_date-selector-dropdown')

    if (!currentTarget.checked) {
      dateSelectorInput.removeAttribute('disabled')
      dateSelectorButton.removeAttribute('disabled')

      return
    }

    dateSelectorInput.value = now
    dateSelectorInput.dispatchEvent(new Event('change'))
    dateSelectorInput.setAttribute('disabled', true)
    dateSelectorButton.setAttribute('disabled', true)
  }
}
