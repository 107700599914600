import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['unreadTab', 'readTab', 'unreadContent', 'readContent', 'markAllAsRead'];

  showUnread() {
    this.toggleTab(this.unreadTabTarget, this.readTabTarget);
    this.toggleContent(this.unreadContentTarget, this.readContentTarget);
    this.markAllAsReadTarget.style.display = 'block';
  }

  showRead() {
    this.toggleTab(this.readTabTarget, this.unreadTabTarget);
    this.toggleContent(this.readContentTarget, this.unreadContentTarget);
    this.markAllAsReadTarget.style.display = 'none';
  }

  preventDropdownClose(event) {
    event.stopPropagation();
  }

  toggleTab(activeTab, inactiveTab) {
    activeTab.classList.add('active');
    inactiveTab.classList.remove('active');
  }

  toggleContent(activeContent, inactiveContent) {
    activeContent.classList.add('active');
    inactiveContent.classList.remove('active');
  }
}

