import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'element', 'generateInvoice' ]

  call(event) {
    switch (event.currentTarget.value) {
      case 'adjustment':
        this.handleAdjustmentPayment()
        break
      default:
        this.handleDefaultPayment()
        break
    }
  }

  handleAdjustmentPayment() {
    this.hide(this.elementTargets)
    this.generateInvoiceTarget.checked = false
    this.generateInvoiceTarget.dispatchEvent(new Event('change'))
  }

  handleDefaultPayment() {
    this.show(this.elementTargets)
  }

  show(targets){
    targets.forEach(target => {
      target.classList.remove('collapse')
    });
  }

  hide(targets){
    targets.forEach(target => {
      target.classList.add('collapse')
    });
  }
}
