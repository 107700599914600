import MassiveSelectionActionController from "../massive_selection_action_controller";

export default class extends MassiveSelectionActionController {
  static targets = ['checkbox', 'actionButton', 'modalList', 'selectAll', 'supIdsContainer'];

  select(event) {
    super.select(event);
    const checkbox = event.target;
    const supplierName = checkbox.closest('.table-row').querySelector("span[id^='supplier_name_']").textContent;
    const supplierId = checkbox.value;

    if (checkbox.checked) {
      this.addSupplierToModalList(supplierName, supplierId);
      this.addSupplierToHiddenInputs(supplierId);
    } else {
      this.removeSupplierFromModalList(supplierId);
      this.removeSupplierFromHiddenInputs(supplierId);
    }
  }

  addSupplierToModalList(supplierName, supplierId) {
    const listItem = document.createElement('li');
    listItem.id = `supplier-${supplierId}`;
    listItem.textContent = supplierName;
    this.modalListTarget.appendChild(listItem);
  }

  removeSupplierFromModalList(supplierId) {
    const listItem = this.modalListTarget.querySelector(`#supplier-${supplierId}`);
    if (listItem) {
      this.modalListTarget.removeChild(listItem);
    }
  }

  addSupplierToHiddenInputs(supplierId) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'sup_ids[]';
    input.value = supplierId;
    input.id = `input-supplier-${supplierId}`;
    this.supIdsContainerTarget.appendChild(input);
  }

  removeSupplierFromHiddenInputs(supplierId) {
    const input = this.supIdsContainerTarget.querySelector(`#input-supplier-${supplierId}`);
    if (input) {
      this.supIdsContainerTarget.removeChild(input);
    }
  }
}
