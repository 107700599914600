import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['calculusBase', 'aliquot', 'type', 'amountField', 'amountInput', 'form', 'reserveFundFixedContainer']

  additionalSelect() {
    if(this.aliquotTarget.value != '' && this.typeTarget.value == 2){
      this.calculusBaseTarget.removeAttribute('style');
    } else {
      this.calculusBaseTarget.style.display = 'none';
    }
  }

  formatAmount() {
    if (this.typeTarget.value == 2) {
      this.setField('percentage')
    } else {
      this.setField('currency')
    }
    $.reloadAllAutonumeric();
    this.additionalSelect()
  }

  setField(datasetKey) {
    this.amountInputTarget.dataset.autonumeric = this.formTarget.dataset[datasetKey]
    this.amountInputTarget.setAttribute('placeholder', I18n.t(`placeholders.fund.${datasetKey}`));
    let field =  this.amountFieldTarget
    let new_field = field.outerHTML
    field.insertAdjacentHTML('afterend', new_field)
    field.remove();
  }

  changeSettingFundType() {
    if (this.hasReserveFundFixedContainerTarget) {
      if (this.typeTarget.value === '2') {
        this.reserveFundFixedContainerTarget.style.display = 'none'
        this.reserveFundFixedContainerTarget.querySelectorAll('input').forEach(input => input.disabled = true)
      } else {
        this.reserveFundFixedContainerTarget.style.display = 'block'
        this.reserveFundFixedContainerTarget.querySelectorAll('input').forEach(input => input.disabled = false)
      }
    }
  }
}
