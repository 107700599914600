export function showConfirmationDialog(acceptCallback, cancelCallback = null) {
  const event = new CustomEvent('confirmation-dialog:show', {
    detail: {
      acceptCallback,
      cancelCallback
    },
    bubbles: true
  })

  document.querySelector('#confirmation-dialog-modal').dispatchEvent(event)
}
