import { Controller } from 'stimulus'
const NOT_SHOW_FUND_INFORMATION = '2'

export default class extends Controller {
  fundLevelDetailInBill(event) {
    let value_setting = event.target.value
    let display = value_setting != NOT_SHOW_FUND_INFORMATION

    this.handleShowInBillFundSetting(display)
  }
  
  handleShowInBillFundSetting(display) {
    let element = document.getElementById('div_show_in_bill_fund_setting')

    if (display){
      element.removeAttribute('style')
    } else {
      element.style.display = 'none'
    }
  }

  updateFields() {
    if (this.countryCodeTarget.value == 'MX') {
      this.mxCompanyTarget.classList.remove('hidden')
    } else {
      this.mxCompanyTarget.classList.add('hidden')
    }
    this.fetchTimezones(this.countryCodeTarget.value)
    this.fetchCurrencies(this.countryCodeTarget.value)
  }

  fetchTimezones(countryCode) {
    const url = '/time_zones/get_timezones'
    const params = new URLSearchParams()
    const self = this
    params.append('country_code', countryCode)
    let urlString = url + '?' + params.toString()

    fetch(urlString)
      .then(response => response.json())
      .then(data => {
        let dropdownMenu = document.getElementById('timezone-select-dropdown-menu');
        if (dropdownMenu) {
          dropdownMenu.querySelectorAll('.dropdown-item').forEach(element => element.remove());
          const timezones = data.timezones
          const communityTimezone = data.timezone
          dropdownMenu.closest('.cf-selector').querySelector('#timezone-select-hidden-value').value = communityTimezone
          dropdownMenu.closest('.dropdown').querySelector('.selected-value').innerHTML = communityTimezone

          for (const timezone of timezones) {
            self.appendOption(timezone[0], timezone[1], communityTimezone, dropdownMenu)
          }
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error)
      })
  }

  fetchCurrencies(countryCode) {
    const url = '/currencies/list'
    const params = new URLSearchParams()
    const self = this
    params.append('country_code', countryCode)
    let urlString = url + '?' + params.toString()

    fetch(urlString)
      .then(response => response.json())
      .then(data => {
        let dropdownMenu = document.getElementById('currency-select-dropdown-menu');
        if (dropdownMenu) {
          dropdownMenu.querySelectorAll('.dropdown-item').forEach(element => element.remove());
          const currencies = data.currencies
          const communityCurrency = data.currency
          dropdownMenu.closest('.cf-selector').querySelector('#currency-select-hidden-value').value = communityCurrency
          dropdownMenu.closest('.dropdown').querySelector('.selected-value').innerHTML = communityCurrency

          for (const currency of currencies) {
            self.appendOption(currency, currency, communityCurrency, dropdownMenu)
          }
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error)
      })
  }
}
