import { DeleteForeverTwoTone } from '@material-ui/icons'
import AutoNumeric from 'autonumeric'
import { Controller } from 'stimulus'
import I18n from 'i18n-js';

export default class extends Controller {
  static targets = [
    'body',
    'row',
    'rowTemplate',
    'deleteButton'
  ]

  connect() {
    if (this.rowTargets.length == 0) {
      this.addRow()
    }
  }

  addRow() {
    const baseRow = this.rowTemplateTarget.content
    const body = this.bodyTarget || this.element.querySelector('tbody')
    body.appendChild(document.importNode(baseRow, true))
    $.reloadAllAutonumeric();
    if (this.rowTargets.length > 1) {
      this.deleteButtonTargets.forEach(button => {
        button.setAttribute('title', I18n.t('views.admin.invoices.form.delete'));
        button.setAttribute('data-original-title', I18n.t('views.admin.invoices.form.delete'));
      });
    }
  }

  deleteRow(event) {
    const row = event.target.closest('tr')
    row.remove()
    if (this.rowTargets.length == 1) {
      this.deleteButtonTarget.setAttribute('title', I18n.t('views.admin.invoices.form.trash_tooltip'));
      this.deleteButtonTarget.setAttribute('data-original-title', I18n.t('views.admin.invoices.form.trash_tooltip'));
    }
  }
}
