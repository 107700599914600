import { Controller } from 'stimulus';
import I18n from 'i18n-js';

export default class extends Controller {
  static targets = ['firstSource', 'secondSource', 'thirdSource', 'fourthSource', 'toFill'];

  connect() {
    this.createObservers();
  }

  createObservers() {
    const observerConfig = { characterData: true, subtree: true, childList: true };

    const observer = new MutationObserver(() => this.fillBlankTarget());

    observer.observe(this.firstSourceTarget, observerConfig);
    observer.observe(this.fourthSourceTarget, observerConfig);
  }

  fillBlankTarget() {
    const firstSource = this.firstSourceTarget.textContent.trim();
    const secondSource = this.secondSourceTarget.value.split('-');
    const thirdSource = this.thirdSourceTarget.value.split('-');
    const fourthSource = this.fourthSourceTarget.textContent.trim();
    const formatedSecond = `${secondSource[1]}/${secondSource[0]}`;
    const formatedThird = `${thirdSource[1]}/${thirdSource[0]}`;
    let result = '';

    if (firstSource && firstSource !== I18n.t('views.admin.invoices.form.choose')) {
      result += `${firstSource} `;
    }

    if (formatedSecond === formatedThird) {
      result += `${I18n.t('common.month')} ${formatedSecond}`;
    } else {
      if (secondSource) {
        result += `${formatedSecond} `;
      }

      if (thirdSource) {
        result += `${I18n.t('common.to')}  ${formatedThird}`;
      }
    }

    if (fourthSource && fourthSource !== I18n.t('views.admin.invoices.form.choose')) {
      result += `. ${fourthSource}`;
    }

    this.toFillTarget.value = result.trim();
  }
}
