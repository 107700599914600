import I18n from 'i18n-js';
import BaseController from './base_controller';

export default class extends BaseController {
  static targets = ['hiddenDate', 'visibleDate']

  connect() {
    this.addLocale();
  }

  formatHiddenDate() {
    const visibleDateValue = this.visibleDateTarget.value
    const [datePart, timePart] = visibleDateValue.split(' ')
    let month, day, year
    if (this.languageEnglish()) {
      [month, day, year] = datePart.split('/');
    } else {
      [day, month, year] = datePart.split('/');
    }

    const formattedDate = `${year}-${month}-${day} ${timePart}`
    this.hiddenDateTarget.value = formattedDate
  }

  languageEnglish() {
    return I18n.locale === 'en';
  }
}
