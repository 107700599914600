import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]
  static values = {
    currentTab: String
  }

  connect() {
    // Update current tab value when frame updates
    if (this.hasLinkTarget) {
      const activeTab = this.linkTargets.find(link =>
        link.querySelector('.menu-item').classList.contains('active')
      )
      if (activeTab) {
        this.currentTabValue = activeTab.dataset.tab
      }
    }
  }

  preventReload(event) {
    const clickedTab = event.currentTarget.dataset.tab
    if (clickedTab === this.currentTabValue) {
      event.preventDefault()
    } else {
      // Update the current tab value when switching tabs
      this.currentTabValue = clickedTab
    }
  }
}
