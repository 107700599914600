import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['checkbox', 'actionButton', 'selectAll'];
  static values = { selectedCount: Number };

  connect() {
    this.selectedCountValue = 0;
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        this.selectedCountValue++;
      }
    });
    this.updateActionButtonState();
  }

  select(event) {
    if (event.target.checked) {
      this.selectedCountValue++;
    } else {
      this.selectedCountValue--;
    }
    this.updateActionButtonState();
    this.updateSelectAllState();
  }

  selectAll(event) {
    const isChecked = event.target.checked;
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked;
      checkbox.dispatchEvent(new Event('change'));
    });
    this.selectedCountValue = isChecked ? this.checkboxTargets.length : 0;
    this.updateActionButtonState();
  }

  updateActionButtonState() {
    if (this.selectedCountValue >= 2) {
      this.actionButtonTarget.classList.remove('disabled');
    } else {
      this.actionButtonTarget.classList.add('disabled');
    }
  }

  updateSelectAllState() {
    if (this.selectedCountValue < this.checkboxTargets.length ) {
      this.selectAllTarget.checked = false;
    } else {
      this.selectAllTarget.checked = true;
    }
  }
}
