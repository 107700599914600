import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  initialize() {
    this.defaults = {
      plugins:
        'insertdatetime lists table code help wordcount autolink link insertdatetime table',
      menubar: false,
      toolbar:
        'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table tableinsertdialog tablecellprops tableprops advtablerownumbering | forecolor backcolor',
    }
  }

  connect() {
    let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(config)
  }

  disconnect() {
    tinymce.remove()
  }
}
