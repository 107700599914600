import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "cancelButton", "confirmButton"]

  connect() {
    // Store the modal instance when using Bootstrap
    this.bootstrapModal = $(this.modalTarget).modal({
      show: false
    })

    // Add event listener for the custom show event
    this.element.addEventListener('confirmation-dialog:show', this.show.bind(this))
  }

  show(event) {
    const { acceptCallback, cancelCallback } = event.detail

    // Store callbacks for later use
    this.acceptCallback = acceptCallback
    this.cancelCallback = cancelCallback

    // Setup button handlers
    this.cancelButtonTarget.removeEventListener('click', this.handleCancel)
    this.confirmButtonTarget.removeEventListener('click', this.handleConfirm)

    this.cancelButtonTarget.addEventListener('click', this.handleCancel.bind(this))
    this.confirmButtonTarget.addEventListener('click', this.handleConfirm.bind(this))

    // Show modal
    $(this.modalTarget).modal('show')
  }

  handleCancel(e) {
    if (this.cancelCallback) {
      this.cancelCallback()
    }
    this.hideModal()
  }

  handleConfirm(e) {
    e.preventDefault()
    e.stopPropagation()

    if (this.acceptCallback) {
      this.acceptCallback()
    }
    this.hideModal()
  }

  hideModal() {
    $(this.modalTarget).modal('hide')
  }

  disconnect() {
    if (this.bootstrapModal) {
      $(this.modalTarget).modal('dispose')
    }

    this.element.removeEventListener('confirmation-dialog:show', this.show)
  }
}
