import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "warningMessage", "submitButton", "wordCounter"]

  static values = {
    maxWords: { type: Number, default: 70 }
  }

  connect() {
    this.submitButton = document.getElementById('form_submit_button')
    this.inputTarget.addEventListener("input", this.validateInput.bind(this))
    this.updateWordCount()
  }

  handleKeydown(event) {
    if (this.shouldHandleLineBreak(event)) {
      this.insertLineBreak(event)
    }
  }

  updateWordCount() {
    const wordCount = this.getWordCount(this.inputTarget.value)
    const remainingWords = this.maxWordsValue - wordCount
    this.wordCounterTarget.textContent = remainingWords

    // Add visual feedback
    if (remainingWords < 0) {
      this.wordCounterTarget.classList.add('text-danger')
    } else {
      this.wordCounterTarget.classList.remove('text-danger')
    }
  }

  validateInput() {
    const { isValid, wordCount } = this.validateTextContent(this.inputTarget.value)
    this.updateFormState(isValid)
    this.updateWordCount()
  }

  onSubmit() {
    this.submitButton.disabled = true
  }

  // Private methods

  shouldHandleLineBreak(event) {
    return event.key === "Enter" && !event.shiftKey
  }

  insertLineBreak(event) {
    event.preventDefault()
    const textarea = event.target
    const { textBeforeCursor, textAfterCursor, cursorPosition } = this.getTextSegments(textarea)

    this.updateTextareaContent(textarea, textBeforeCursor, textAfterCursor, cursorPosition)
    this.ensureCursorVisible(textarea)
    this.validateInput()
  }

  getTextSegments(textarea) {
    const cursorPosition = textarea.selectionStart
    return {
      textBeforeCursor: textarea.value.substring(0, cursorPosition),
      textAfterCursor: textarea.value.substring(cursorPosition),
      cursorPosition
    }
  }

  updateTextareaContent(textarea, textBeforeCursor, textAfterCursor, cursorPosition) {
    textarea.value = `${textBeforeCursor}\n${textAfterCursor}`
    textarea.selectionStart = textarea.selectionEnd = cursorPosition + 1
  }

  ensureCursorVisible(textarea) {
    requestAnimationFrame(() => {
      textarea.scrollTop = textarea.scrollHeight
    })
  }

  getWordCount(text) {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length
  }

  validateTextContent(text) {
    const trimmedText = text.trim()
    const words = trimmedText.split(/\s+/).filter(word => word.length > 0)
    const wordCount = words.length

    return {
      isValid: wordCount >= 1 && wordCount <= this.maxWordsValue && trimmedText.length >= 10,
      wordCount
    }
  }

  updateFormState(isValid) {
    this.submitButton.disabled = !isValid
    this.warningMessageTarget.classList.toggle('hidden', isValid)
  }
}
