import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectAllGuests', 'guestRegistryRow', 'sendInvitationsBtn', 'sendInvitationsForm']

  // Checkbox ALL changed
  toggleAll() {
    let newValue = false;

    if (this.selectAllGuestsTarget.checked) {
      newValue = true;
      this.enableSendBtn();
    } else {
      this.disableSendBtn();
    }

    this.guestRegistryRowTargets.forEach((row) => row.checked = newValue)
  }

  // Checkbox changed
  toggleSendInvitationsBtn(event) {
    if (event.currentTarget.checked || this.guestRegistryRowTargets.find((row) => row.checked))
      this.enableSendBtn();
    else {
      this.disableSendBtn();
      this.selectAllGuestsTarget.checked = false;
    }
  }

  // Send invitations btn clicked
  openSendInvitationModal() {
    const guestRegistryIds = [];

    this.guestRegistryRowTargets.forEach(function(row) {
      if (row.checked)
        guestRegistryIds.push(row.value);
    })

    this.updateModalBody(guestRegistryIds);
  }

  // Modal send btn clicked
  sendGuestRegistryIds() {
    event.preventDefault();
    this.sendInvitationsFormTarget.submit();
  }

  // Helpers
  enableSendBtn() {
    this.sendInvitationsBtnTarget.classList.remove('disabled');
  }

  disableSendBtn() {
    this.sendInvitationsBtnTarget.classList.add('disabled');
  }

  updateModalBody(guestRegistryIds) {
    const checkedRows = guestRegistryIds.length;
    const sendInvitationsModal = document.getElementById('modal-send-invitations');
    sendInvitationsModal.querySelector('.send-invitations-modal-count').innerHTML = checkedRows;
    sendInvitationsModal.querySelector('#guest_registry_ids').value = guestRegistryIds;
    const pluralTxt = sendInvitationsModal.querySelector('.send-invitations-modal-count-plural');
    const singularTxt = sendInvitationsModal.querySelector('.send-invitations-modal-count-singular');

    if (checkedRows == 1) {
      pluralTxt.classList.add('hide');
      singularTxt.classList.remove('hide');
    } else {
      pluralTxt.classList.remove('hide');
      singularTxt.classList.add('hide');
    }
  }
}

