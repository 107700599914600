import CurrencyInputBaseController from "./currency_input_base_controller";

export default class extends CurrencyInputBaseController {
  
  static targets = [...CurrencyInputBaseController.targets]
  static values = { validations: Object }

  connect() {
    super.connect(); // Calls the base controller logic
  }

  verifyInput(event) {
    let currentNumberValue = this.numberInputTarget.value
    let currentStringValue = this.stringInputTarget.value

    super.verifyInput(event); // Calls the method from the base controller
    setTimeout(() => {
      if (Object.keys(this.validationsValue).length > 0) { this.validateInput(currentNumberValue, currentStringValue) }
    }, 0)
  }

  validateInput(currentNumberValue, currentStringValue){
    if (this.validationsValue['max']){
      this.checkMaximumValue(currentNumberValue, currentStringValue)
    }
  }

  checkMaximumValue(currentNumberValue, currentStringValue){
    if (Number(this.numberInputTarget.value) > this.validationsValue['max']){
      this.numberInputTarget.value = currentNumberValue
      this.stringInputTarget.value = currentStringValue
      this.addEventChange(this.numberInputTarget)
    }
  }
}
